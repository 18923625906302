<template>
  <div class="ca-horizontal-nav h-nav">
    
    <div class="menuicon" v-if="menuIcon" @click="$emit('showSidebar')">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu">
          <line x1="3" y1="12" x2="21" y2="12"></line>
          <line x1="3" y1="6" x2="21" y2="6"></line>
          <line x1="3" y1="18" x2="21" y2="18"></line>
        </svg>
    </div>
    <!-- <div class="h-nav__child h-nav__child--search">
      <search-bar></search-bar>
    </div> -->
    <div class="h-nav__child h-nav__child--profile">
      <profile-dropdown></profile-dropdown>
    </div>
  </div>
</template>

<script>
// import SearchBar from "./components/HorizontalNav/SearchBar";
import ProfileDropdown from "./components/HorizontalNav/ProfileDropdown";
export default {
  components: { ProfileDropdown },
  props: {
    menuIcon: {
      type: Boolean,
      
    }
  },
  data() {
    return {};
  },
  created() {
    console.log(this.menuIcon);
  },
};
</script>
<style scoped>
.menuicon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  left: 20px;
  top: 20px;
}
.menuicon svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>