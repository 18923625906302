export default [
  {
    url: "/",
    name: "Dashboard",
    slug: "dashboard",
    icon: "dashboard",
  },
  {
    url: "/users",
    name: "Users",
    slug: "users",
    icon: "users",
  },
  {
    url: "/company-info",
    name: "Company Info",
    slug: "company-info",
    icon: "company_info",
  },
  {
    url:'/investors',
    name:'Investors',
    slug:'investors',
    icon:'company_info',

  },
  {
    url: "/subscriptions",
    name: "Subscriptions",
    slug: "subscriptions",
    icon: "subscriptions",
  },
  {
    url: "/payment-history",
    name: "Payment History",
    slug: "payment-history",
    icon: "history",
  },
  {
    url: "/coupons",
    name: "Coupons",
    slug: "coupons",
    icon: "coupons",
  },
  {
    url: "/packages",
    name: "Packages",
    slug: "packages",
    icon: "packages",
  },
  {
    url: "/admins",
    name: "Admins",
    slug: "admins",
    icon: "admins",
  },
];
