<template>
  <router-link :to="to" class="ca-nav-item" exact>
    <img
      :src="require(`@/assets/images/icons/${icon}.svg`)"
      :alt="name"
      ref="image"
      class="ca-nav-item__img"
    />
    <span class="ca-nav-item__title">
      {{ name }}
    </span>
  </router-link>
</template>

<script>
export default {
  name: "NavItem",
  data() {
    return {
      navIcon: "",
    };
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
  },

  methods: {
    goTo: function () {
      this.$router.to(this.to);
    },
  },
};
</script>
