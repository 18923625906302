<template>
  <div class="ca-logo">
    <img src="~@/assets/images/logo@2x.png" alt="Caena Logo" />
  </div>
</template>

<script>
export default {
  name: "Logo",
};
</script>

<style scoped>
.ca-logo {
  width: 100%;
  padding: 3rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ca-logo > img {
  width: 9rem;
  height: 4.4rem;
}
</style>
