<template>
  <div class="layout--main main">
    <div class="v-nav-container" v-show="showNavbar">
      
      <vertical-navbar></vertical-navbar>
    
    </div>
    <div class="full-page-container">
      <full-page>
        <horizontal-navbar
        :menuIcon="menuIcon"
        @showSidebar="showSidebar"
       
        ></horizontal-navbar>
        <div class="page-container" @click="hideSidebar">
          <router-view></router-view>
        </div>
      </full-page>
    </div>
  </div>
</template>

<script>
import VerticalNavbar from "./components/navbar/VerticalNavbar.vue";
import HorizontalNavbar from "./components/navbar/HorizontalNavbar.vue";
import FullPage from "./components/fullPage/FullPage";
export default {
  components: { FullPage, VerticalNavbar, HorizontalNavbar },
  data() {
    return {
      showNavbar:true,
      windowWidth: window.innerWidth,
      menuIcon: false,
    };
  },
  watch: {
    windowWidth(val){
      console.log(val);
     
    }
    
  },
  methods: {
    handleResize(){
      console.log(window.outerWidth);
       if(window.outerWidth < 1200){
        this.showNavbar = false;
        this.menuIcon = true;
      }
      else{
        this.showNavbar = true;
        this.menuIcon = false;
      }
    },
    showSidebar(){
      if (window.outerWidth < 1200) {
        this.showNavbar = !this.showNavbar;
      }
    },
    hideSidebar(){
      if(window.outerWidth < 1200){
        this.showNavbar = false;
      }
    }

  },
  created() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped lang="scss">
.layout--main {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.v-nav-container {
  flex: 0 0 22rem;
}
.full-page-container {
  flex: 1;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
@media screen and (max-width: 1200px) {

  .v-nav-container {
    position: absolute;
    z-index: 1000;
    height: 100vh;
    top: 0;
    left: 0;
  }


  
  
}
</style>
