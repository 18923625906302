<template>
  <div class="ca-vertical-nav">
    <logo></logo>
    <div class="">
      <nav-item
        v-for="(item, index) in navItems"
        :key="index"
        :icon="item.icon"
        :name="item.name"
        :to="item.url"
      ></nav-item>
    </div>
  </div>
</template>

<script>
import Logo from "./components/Logo";
import NavItem from "./components/verticalNav/NavItem";
import navMenuItems from "./components/verticalNav/navMenuItems";
export default {
  components: { NavItem, Logo },
  data() {
    return {
      navItems: [],
    };
  },
  methods: {
    resolveNavItems() {
      for (const item of navMenuItems) {
        this.navItems.push(item);
      }
    },
  },

  created() {
    this.resolveNavItems();
  },
};
</script>

<style></style>
