export default [
    {
      url: "/profile",
      name: "Profile",
    },
    {
      url: "/profile/settings",
      name: "Settings",
    },
    {
      url: "/logout",
      name: "Logout",
    },
    
  ];
  