<template>
  <div @click="showDropDown" class="profile-dd">
    <p class="dd-label">
      <span> {{ loggedInUserInitials }} </span>
      {{ loggedInUsername }}
    </p>

    <div v-show="show" class="profile-dd-group">
        <div class="initials">
          <span>
            {{loggedInUserInitials}}
          </span>
        </div>
        <div class="profile-name">{{loggedInUsername}}</div>
        <div class="profile-email">{{loggedInUserEmail}}</div>

        <div class="sign-out" @click="loggout">
          <span>Sign Out</span>
        </div>
    </div>
  </div>
</template>

<script>
// import ProfileDropDownItem from "./dropdownItem/ProfileDropDownItem.vue";
import ddMenu from "./dropdownItem/dropDownmenu";
export default {
  name: "ProfileDropdown",
  // components: {
  //   ProfileDropDownItem,
  // },
  data() {
    return {
      items: [],
      show: false,
    };
  },
  computed: {
    loggedInUsername() {
      return this.$store.getters.loggedInUserName;
    },

    loggedInUserInitials() {
      return this.$store.getters.loggedInUserInitials;
    },
    loggedInUserEmail() {
      return this.$store.getters.loggedInUserEmail;
    },
  },
  methods: {
    resolveDropDownItem() {
      this.items = ddMenu;
    },
    loggout(){
      this.$store.commit("removeToken");
      this.$store.commit("removeLoggedInUser");
      this.$toast("Logout successful", {
        type: "success",
        position: "top-center",
      });
      this.$router.push({ name: "admin-login" });
  
    },
    showDropDown() {
      this.show = !this.show
    },
      
  },
  created() {
    this.resolveDropDownItem();
  },
};
</script>
<style scoped>
.dd-label {
  margin-bottom: 10px;
  padding: 1rem 0.25rem;
  cursor: pointer;
}
.profile-dd-group {
  position: absolute;
  padding: 0.5rem 0;
  top: 50px;
  left: -30%;
  width: 130%;
  text-align: center;
  /* position: relative; */
  /* height: 200px; */
  /* display: flex;
    flex-wrap: wrap; */
  /* justify-content: center;
  align-items: center; */
  background-color: rgb(255, 255, 255);
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.486);
  z-index: 1000;
  /* opacity: 0;
    visibility: hidden; */
  transition: all 0.3s ease-in-out;
}
.dd-label span {
  /* font-size: 1.5rem; */
  /* font-weight: bold; */
  color: var(--unnamed-color-ffffff);
  color: #ffffff;
  text-align: center;
  border-radius: 50%;
  font: normal normal normal 16px/34px Poppins;
  letter-spacing: 0px;
  padding: 0.5rem 0.4rem 0.5rem 0.5rem;
  margin-right: 0.4rem;
  background: var(--unnamed-color-1d817f) 0% 0% no-repeat padding-box;
  background: #1d817f 0% 0% no-repeat padding-box;
  opacity: 1;
}

.initials{
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #1d817f 0% 0% no-repeat padding-box;
  margin: 10px auto;
  color: #fff;
  font: normal normal normal 40px/69px Poppins;
}

.profile-name{
  color: var(--unnamed-color-222222);
  /* text-align: left; */
  font: normal normal normal 21px/36px Poppins;
  letter-spacing: 0px;
  color: #222222;
  padding: 0 0 2px 0;
}
.profile-email{
  font: normal normal normal 16px/36px Poppins;
  letter-spacing: 0px;
  color: #646363;
  padding: 0 0 40px 0;
}

.sign-out{
  width: 100%;
  padding: 10px 20px;
  border-top: 1px solid #808080;
  text-align: left;
  cursor: pointer;
}
</style>
